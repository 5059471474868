import { createSelector } from 'reselect';
import { getActiveWorkbook } from 'common-selectors/get-active-workbook';
import { getActiveWorksheetId } from 'common-selectors/get-active-worksheet-id';
import { isWorksheetLoadingStatus } from 'constants/enums/worksheet-status';
import { sortArrayObjectsBy } from 'utilities/array-object-sort';

export const getWorkbookProps = createSelector(
  getActiveWorkbook,
  (state) => state.appMetadata.stageLeftPanel,
  getActiveWorksheetId,
  (state) =>
    Object.values(state.worksheetsById).filter(
      (worksheet) => worksheet.workbookId === state.activeWorkbookId
    ),
  (state) => {
    const { activeWorksheetId, worksheetsById } = state;
    const currentWorksheet = worksheetsById[activeWorksheetId];
    if (!currentWorksheet) {
      return false;
    }

    return isWorksheetLoadingStatus(currentWorksheet.status);
  },
  (workbook, stageLeftPanel, activeWorksheetId, worksheets, isWorksheetLoading) => {
    const notSortedWorksheets = [];
    worksheets.forEach((worksheet) => {
      if (
        workbook.worksheetSortOrderByIds &&
        !workbook.worksheetSortOrderByIds.includes(worksheet.id)
      ) {
        notSortedWorksheets.push(worksheet);
      }
    });

    const sortOrderType = workbook.sortOrderType;

    const seenWorksheets = new Set();
    const worksheetsSortedForDisplay = [
      ...sortArrayObjectsBy(worksheets, sortOrderType, 'name', workbook.worksheetSortOrderByIds),
      ...notSortedWorksheets,
    ]
    // removes duplicates
    .filter(
      (worksheet) => !seenWorksheets.has(worksheet.id) && seenWorksheets.add(worksheet.id)
    );

    return {
      worksheets,
      worksheetsSortedForDisplay,
      activeWorksheetId,
      workbook,
      selectedWorksheetPanel: stageLeftPanel,
      sortOrderType,
      isWorksheetLoading,
    };
  }
);
