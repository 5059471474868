import { createSelector } from 'reselect';
import { getActiveWorkbook } from 'common-selectors/get-active-workbook';
import { sortArrayObjectsBy } from 'utilities/array-object-sort';

export const selector = createSelector(
  getActiveWorkbook,
  (state) =>
    Object.values(state.worksheetsById).filter(
      (worksheet) => worksheet.workbookId === state.activeWorkbookId
    ),
  (workbook, worksheets) => {
    const notSortedWorksheets = [];
    worksheets.forEach((worksheet) => {
      if (
        workbook.worksheetSortOrderByIds &&
        !workbook.worksheetSortOrderByIds.includes(worksheet.id)
      ) {
        notSortedWorksheets.push(worksheet);
      }
    });

    const sortOrderType = workbook.sortOrderType;

    const seenWorksheets = new Set();
    const worksheetsSortedForDisplay = [
      ...sortArrayObjectsBy(worksheets, sortOrderType, 'name', workbook.worksheetSortOrderByIds),
      ...notSortedWorksheets,
    ]
    // removes duplicates
    .filter(
      (worksheet) => !seenWorksheets.has(worksheet.id) && seenWorksheets.add(worksheet.id)
    );

    return {
      workbook,
      sortOrderType,
      worksheetsSortedForDisplay,
    };
  }
);
